export const englishMessages = {
    // Search Page
    "Booking": "Booking",
    "Confirmation": "Confirmation",
    "Round Trip": "Round Trip",
    "One Way": "One Way",
    "Multi City": "Multi-destinations",
    "Economy Class": "Economy Class",
    "Premium Economy": "Premium Economy",
    "Business Class": "Business Class",
    "First Class": "First Class",
    "Economy": "Economy",
    "Premium": "Premium",
    "Business": "Business",
    "First": "First",
    "Departure dates": "Departure dates",
    "Departure and return dates": "Departure and return dates",
    "Adult": "Adult",
    "Adults": "Adults",
    "Children": "Children",
    "Search": "Search",
    "Filter": "Filter",
    "Clear All": "Clear All",
    "From": "From",
    "to": "to",
    "Airlines": "Airlines",
    "Email address": "Email address",
    "Operated by": "Operated by",
    "or from": "or from",
    "Flight Details": "Flight Details",
    "Add Flight": "Add Flight",
    "fare": "fare",
    "Layover": "Layover",
    "Price Range": "Price Range",
    "Select Flight": "Select Flight",
    "Departing From": "Departing From",
    "Departing from": "Departing From",
    "Going To": "Going To",
    "No options": "No options",
    "Best Departing ": "Best Departing Flights",
    "Best Departing Flights": "Best Departing Flights",
    "Best Returning Flights": "Best Returning Flights",
    "Best": "Best",
    "Departing": "Departing",
    "Flights": " Flights",
    "Ranked based on price and convenience": "Ranked based on price and convenience",
    "Recommended": "Recommended",
    "Stop": "Stop",
    "1 Stop": "1 Stop",
    "2+ Stop": "2+ Stop",
    "NonStop": "NonStop",
    "Non-stop": "Non-stop",
    "stop": "stop",
    "Only": "Only",
    "Stay updated with the latest travel deals": "Stay updated with the latest travel deals",
    "Get Updates": "Get Updates",
    "SEGMENT": "SEGMENT",
    "Enter date": "Enter date",
    "Remove Last Flight": "Remove Last Flight",
    "in": "in",
    "minimumValue": "minimumValue",
    "maximumValue": "maximumValue",
    "count": "count",
    "Baggage Allowance": "Baggage Allowance",
    "Clear": "Clear",
    "Timings": "Timings",
    "Take-off from": "Take-off from",
    "Travel Time": "Travel Time",
    "Flight Duration": "Flight Duration",
    "Layover Airports": "Layover Airports",
    "Airports": "Airports",
    "Help us improve your booking experience": "Help us improve your booking experience",
    "Give feedback": "Give feedback",
    "hr": "hr",
    "min": "min",
    "Check-In Bag Not Included": "Check-In Bag Not Included",
    "Landing at": "Landing at",

    // Branded fares
    "Cabin": "Cabin",
    "Bags": "Bags",
    "PremiumEconomy": "PremiumEconomy",
    "BusinessClass": "BusinessClass",
    "FirstClass": "FirstClass",
    "Select your fare": "Select your fare",
    "Flexibility": "Flexibility",
    "Select": "Select",
    "Seat": "Seat",
    "Priority": "Priorité",
    "BAGS": "BAGS",
    "CHECKED BAGS INCLUDED": "CHECKED BAGS INCLUDED",
    "FEE FOR ADDITIONAL BAG": "FEE FOR ADDITIONAL BAG",
    "1 CABIN BAG UP TO 8KG": "1 CABIN BAG UP TO 8KG",
    "2 CABIN BAGS UP TO 8KG": "2 CABIN BAGS UP TO 8KG",
    "SEAT": "SEAT",
    "SEAT CHOICE": "SEAT CHOICE",
    "BASIC SEAT": "BASIC SEAT",
    "EXTRA LEG ROOM": "EXTRA LEG ROOM",
    "STANDARD AND PREFERRED SEATS": "STANDARD AND PREFERRED SEATS",
    "STANDARD SEAT RESERVATION": "STANDARD SEAT RESERVATION",
    "PREFERRED SEAT RESERVATION": "PREFERRED SEAT RESERVATION",
    "Changes & Refund": "Changes & Refund",
    "REFUNDS": "REFUNDS",
    "CHANGES": "CHANGES",
    "CHANGE BEFORE DEPARTURE": "CHANGE BEFORE DEPARTURE",
    "CHANGE AFTER DEPARTURE": "CHANGE AFTER DEPARTURE",
    "REFUND BEFORE DEPARTURE": "REFUND BEFORE DEPARTURE",
    "REFUND AFTER DEPARTURE": "REFUND AFTER DEPARTURE",
    "SAME DAY CHANGE": "SAME DAY CHANGE",
    "CHANGEABLE TICKET": "CHANGEABLE TICKET",
    "REFUNDABLE TICKET": "REFUNDABLE TICKET",
    "PRIORITY": "PRIORITY",
    "PRIORITY BAGGAGE": "PRIORITY BAGGAGE",
    "PRIORITY CHECK IN": "PRIORITY CHECK IN",
    "PRIORITY SECURITY": "PRIORITY SECURITY",
    "PRIORITY BOARDING": "PRIORITY BOARDING",
    "FAST TRACK SECURITY": "FAST TRACK SECURITY",
    "DEDICATED CHECK IN ZONE": "DEDICATED CHECK IN ZONE",
    "SABRE_NDC BRAND FEATURES": "SABRE_NDC BRAND FEATURES",
    "CHECK 2 BAGS FREE": "CHECK 2 BAGS FREE",
    "CHOOSE A LIE-FLAT SEAT": "CHOOSE A LIE-FLAT SEAT",
    "PRIORITY CHECK-IN AND BOARDING ACCESS (GROUPS 1-2)": "PRIORITY CHECK-IN AND BOARDING ACCESS (GROUPS 1-2)",
    "FLAGSHIP LOUNGE AND ADMIRALS CLUB ACCESS": "FLAGSHIP LOUNGE AND ADMIRALS CLUB ACCESS",
    "CHANGES ALLOWED": "CHANGES ALLOWED",
    "FULL REFUND TO ORIGINAL FORM OF PAYMENT": "FULL REFUND TO ORIGINAL FORM OF PAYMENT",
    "IF YOU'RE AN AADVANTAGE® STATUS MEMBER OR AADVANTAGE® CREDIT CARDHOLDER, YOUR BAG BENEFIT AND BOARDING GROUP APPLIES IF HIGHER BASED ON STATUS. BENEFITS MAY NOT APPLY ON PARTNER AIRLINES.": "IF YOU'RE AN AADVANTAGE® STATUS MEMBER OR AADVANTAGE® CREDIT CARDHOLDER, YOUR BAG BENEFIT AND BOARDING GROUP APPLIES IF HIGHER BASED ON STATUS. BENEFITS MAY NOT APPLY ON PARTNER AIRLINES.",
    "IF YOU'RE AN AADVANTAGE® CREDIT CARDHOLDER, YOUR BAG BENEFIT APPLIES ONLY ON DOMESTIC AMERICAN AIRLINES MARKETED AND OPERATED TRIPS.": "IF YOU'RE AN AADVANTAGE® CREDIT CARDHOLDER, YOUR BAG BENEFIT APPLIES ONLY ON DOMESTIC AMERICAN AIRLINES MARKETED AND OPERATED TRIPS.",
    "AADVANTAGE® MEMBERS TRAVELING WITHIN THE U.S. CAN CANCEL BASIC ECONOMY FARE TICKETS ON AA.COM OR AMERICAN APP FOR A TRAVEL CREDIT, LESS A BASIC ECONOMY CANCELLATION FEE.": "AADVANTAGE® MEMBERS TRAVELING WITHIN THE U.S. CAN CANCEL BASIC ECONOMY FARE TICKETS ON AA.COM OR AMERICAN APP FOR A TRAVEL CREDIT, LESS A BASIC ECONOMY CANCELLATION FEE.",
    "CHOOSE A MAIN CABIN SEAT": "CHOOSE A MAIN CABIN SEAT",
    "PAY FOR AN EXTRA LEGROOM SEAT": "PAY FOR AN EXTRA LEGROOM SEAT",
    "BOARD IN GENERAL GROUP (6-8)": "BOARD IN GENERAL GROUP (6-8)",
    "DESCRIPTIONS": "DESCRIPTIONS",

    // Payment Confirmation
    "Title": "Title",
    "None": "None",
    "MR": "MR",
    "MS": "MS",
    "MRS": "MRS",
    "First Name": "First Name",
    "Middle Name": "Middle Name",
    "Last Name": "Last Name",
    "Date Of Birth": "Date Of Birth",
    "Day": "Day",
    "Month": "Month",
    "Year": "Year",
    "Gender": "Gender",
    "Male": "Male",
    "Female": "Female",
    "Email": "Email",
    "Enter phone number": "Enter phone number",
    "Frequent Flyer Program": "Frequent Flyer Program",
    "Expiry Date": "Expiry Date",
    "CVV Code": "CVV Code",
    "City": "City",
    "Flight": "Flight",
    "Price Summary": "Price Summary",
    "Taxes and fees": "Taxes and fees",
    "Passenger": "Passenger",
    "selected": "selected",
    "Total": "Total",
    "Traveller Details": "Traveller Details",
    "Traveller": "Traveller",
    "Fare type": "Fare type",
    "of": "of",

    // Booking Confirmation
    "Departure date": "Departure date",
    "Departure": "Departure",
    "Return": "Return",
    "Not Available": "Not Available",
    "Please scroll up to review":
        "Please scroll up to review the error messages and update the data accordingly.",
    "Please select the above checkbox to proceed.": "Please select the above checkbox to proceed.",
    "You are about to book an unaccompanied minor (UMNR).":
        "You are about to book an unaccompanied minor (UMNR). You must contact the carrier to check if your passenger will be accepted on board and if there are any mandatory procedures that must be followed.",
    "I agree to contact the airline.":
        "I agree to contact the airline. Continue and book.",
    "Remove": "Remove",

    "Traveller Information": "Traveller Information",
    "Program Number": "Program Number",
    "Protect Your Travel Investment with our Insurance Plans": "Protect Your Travel Investment with our Insurance Plans",
    "Coverage valid for Canadian residents only": "Coverage valid for Canadian residents only.",
    "Customize Your Booking": "Customize Your Booking",
    "Choose seats": "Choose seats",
    "Show Seatmap": "Show Seatmap",
    "Select Payment option": "Select Payment option",
    "Pay the full amount": "Pay the full amount",
    "Pay Monthly": "Pay Monthly",
    "Payment Details": "Payment Details",
    "We Use Secure Transmission & Protect Your Personal Information": "We Use Secure Transmission & Protect Your Personal Information",
    "We accept all major credit cards": "We accept all major credit cards",
    "Name On Card": "Name On Card",
    "Credit Card Number": "Credit Card Number",
    "Billing Address": "Billing Address",
    "Street Name And House No": "Street Name And House No.",
    "Country": "Country",
    "State": "State",
    "Province": "Province",
    "Postal Code": "Postal Code",
    "Complete Purchase": "Complete Purchase",
    "Full Flight Details": "Full Flight Details",
    "Flight Price": "Flight Price",
    "Base price": "Base price",
    "Total price": "Total price",
    "Full price breakdown": "Full price breakdown",
    "Confirmation will be sent here": "Confirmation will be sent here",
    "Seat selected": "Seat selected",
    "Seatmap Key": "Seatmap Key",
    "middle": "middle",
    "Add Seat": "Add Seat",
    "Skip": "Skip",
    "Halte": "Halte",
    "Filter & Sort by": "Filter & Sort by",
    "Thank you for booking with": "Thank you for booking with",
    "Your flight booking is currently": "Your flight booking is currently",
    "You have declined travel insurance.": "You have declined travel insurance.",
    "Important Information": "Important Information",
    "Please do not attempt to rebook as it may result in duplicating your booking error and payment. Prices and availability are subject to change and are not guaranteed until payment has been processed.":
        "Please do not attempt to rebook as it may result in duplicating your booking error and payment. Prices and availability are subject to change and are not guaranteed until payment has been processed.",
    "Please ensure that all your booking details are correct and verify that all passenger names are exactly as they appear on your valid passport(s).":
        "Please ensure that all your booking details are correct and verify that all passenger names are exactly as they appear on your valid passport(s).",
    "Rates and availability are subject to change and are not guaranteed until payment has been processed. Call us at 1.866.573.3824 if you have any questions.":
        "Rates and availability are subject to change and are not guaranteed until payment has been processed. Call us at 1.866.573.3824 if you have any questions.",
    "You are responsible for reconfirming your flight times with Air Canada at least 24 hours prior to your flight departure via their website, by calling them or check with your local airport authority. You must check in at least 3 hours prior to your flight departure.":
        "You are responsible for confirming your flight times with the airline at least 24 hours prior to departure, either via their website, by calling them, or by checking with your local airport authority. Additionally, you must check in at least 3 hours before your flight departure.",
    "Terms Of Booking": "Terms Of Booking",
    "Your itinerary consists of two one-way fares which are subjects to their own rules and restrictions. If one of your flights is changed or canceled, it will not automatically change the other flight. You may incur a penalty free for each flight for additional itinerary changes.":
        "Your itinerary consists of two one-way fares which are subjects to their own rules and restrictions. If one of your flights is changed or canceled, it will not automatically change the other flight. You may incur a penalty free for each flight for additional itinerary changes.",
    "Taxes and fees are included in the total ticket costs. Prices may not include (baggage fee) or other fees charged directly by the airline. Fares are not guaranteed until ticketed. Service fees are non-refundable. Cancellations, routing and date changes are subject to (fare rules) and our (fees), should these changes be allowed by the airline.":
        "Taxes and fees are included in the total ticket costs. Prices may not include (baggage fee) or other fees charged directly by the airline. Fares are not guaranteed until ticketed. Service fees are non-refundable. Cancellations, routing and date changes are subject to (fare rules) and our (fees), should these changes be allowed by the airline.",
    "Travel Insurance Details": "Travel Insurance Details",
    "Manulife Policy": "Manulife Policy",
    "DECLINED": "DECLINED",
    "You Have DECLINED Travel Insurance. Travel insurance is designed to offer you a worry free travelling experience. Emergencies can occur anywhere. Travel insurance protects you against unforseen circumstances.":
        "You Have DECLINED Travel Insurance. Travel insurance is designed to offer you a worry free travelling experience. Emergencies can occur anywhere. Travel insurance protects you against unforseen circumstances.",
    "Billing Details": "Billing Details",
    "Supplier": "Supplier",
    "Booking Code": "Booking Code",
    "Paid - Not Ticketed": "Paid - Not Ticketed",
    "Full Name": "Full Name",
    "Credit Card Holder": "Credit Card Holder",
    "Card Type": "Card Type",
    "Payment Total": "Payment Total",
    "Payment Method": "Payment Method",
    " Insurance:": " Insurance:",
    "You have applied too many filters": "You have applied too many filters.",
    "Click": "Click",
    "here": "here",
    "to View all results": "to View all results.",
    "Interest-free payments also available":
        "Interest-free payments also available",
    "Pay monthly with": "Pay monthly with",
    "Something went wrong": "Something went wrong", // Remain to map with t
    "Child's age upon return": "Child's age upon return",
    "Multiple flights": "Multiple flights",
    "Multiple Flight Details":"Multiple Flight Details",
    "For Coverage amounts click": "For Coverage amounts click",
    "Show Less": "Show Less",
    "Show More": "Show More",
    "per day": "per day",
    "Starting from": "Starting from",
    "per person": "per person",
    "Enter a valid mobile number": "Enter a valid mobile number",
    "Your Booking": "Your Booking",
    "is processing please wait": "is processing please wait",
    "Woops": "Woops",
    "Someting went wrong": "Someting went wrong",
    "There was a problem processing the request": "There was a problem processing the request",
    "Go to Home": "Go to Home",
    "Oops! Page not found": "Oops! Page not found",
    "The page you are looking for was not found": "The page you are looking for was not found.",
    "Select a flight": "Select a flight",
    "Passengers with same name are not allowed": "Passengers with same name are not allowed",
    "I read and agree to redtag.ca's": "I read and agree to redtag.ca's",
    "terms & conditions": "terms & conditions",
    "and the supplier’s": "and the supplier’s",
    "Add More": "Add More",
    "As per your Passport": "As per your Passport",
    "Mobile Number": "Mobile Number",
    "Facilities": "Facilities",
    "Locations": "Locations",
    "Characteristics": "Characteristics",
    "Limitations": "Limitations",
    "with": "with",
    "Change Flight": "Change Flight",
    "Address": "Address",
    "Postal": "Postal",
    "Infant": "Infant",
    "Child": "Child",
    "Invalid": "Invalid",
    "Name": "Name",
    "Card Number": "Card Number",
    "Select Seat": "Select Seat",
    "Pay monthly from": "Pay monthly from",
    "Add Insurance": "Add Insurance",
    "Unable to retrieve seat map. Please try again": "Unable to retrieve seat map. Please try again.",
    "Add Gift Card": "Add Gift Card",
    "Important": "Important",
    "Online redemption only valid for cards purchased after": "Online redemption only valid for cards purchased after",
    "please contact a redtag agent to redeem cards issued prior to that date": "please contact a redtag agent to redeem cards issued prior to that date.",
    "Add More Gift Cards": "Add More Gift Cards",
    "Gift Card": "Gift Card",
    "Gift Card Number": "Gift Card Number",
    "Digit Pin": "Digit Pin",
    "Apply": "Apply",
    "Flexible payment options": "Flexible payment options",
    "We share program details with the airline, though we cannot guarantee point awards": "We share program details with the airline, though we cannot guarantee point awards.",
    "Loading": "Loading",
    "Available Balance": "Available Balance",
    "Booking Summary": "Booking Summary",
    "Select different Outbounds": "Select different Outbounds",
    "Returning": "Returning ",
    "Check-In Bag Included": "Check-In Bag Included",
    "Zip Code": "Zip Code",
    "processing": "processing",
    "Phone Number": "Phone Number",
    "Enter Your Promo Code": "Enter Your Promo Code",
    "Booked Seats Overview": "Booked Seats Overview",
    "Decline Insurance": "Decline Insurance",
    "Travel Insurance": "Travel Insurance",
    "Checked Bags Included": "Checked Bags Included",
    "Descriptions": "Descriptions",
    "Add": "Add",
    "Apply a promo code": "Apply a promo code",
    "Your previous Promo has been removed successfully": "Your previous Promo has been removed successfully",
    "Please Enter Promocode.": "Please Enter Promocode",
    "It seems that there\'s an issue applying this Promo code to your booking. Please call": "It seems that there\'s an issue applying this Promo code to your booking. Please call",
    "for assistance": "for assistance",
    "Receive a $20 Amazon Gift Card with Flight Bookings": "Receive a $20 Amazon Gift Card with Flight Bookings.",
    "Gift card applied successfully": "Gift card applied successfully",
    "Street Address": "Street Address",
    "Payment": "Payment",
    "Return Date": "Return Date",
    "Destination": "Destination",
    "Sort by Price (Low to High)": "Sort by Price (Low to High)",
    "Sort by Price (High to Low)": "Sort by Price (High to Low)",
    "Sort by Duration (Short to Long)": "Sort by Duration (Short to Long)",
    "Sort by Duration (Long to Short)": "Sort by Duration (Long to Short)",
    "Fill required information": "Fill required information",
    "Pricing Failed!": "Pricing Failed!",
    "Booking Failed !": "Booking Failed !",
    "Please enter gift card pin": "Please enter gift card pin",
    "Please enter gift card number": "Please enter gift card number",
    "PIN must be a 4-digit number": "PIN must be a 4-digit number",
    "Gift Card invalid or no funds available": "Gift Card invalid or no funds available",
    "Invalid Program Number": "Invalid Program Number",
    "provinceChange": "provinceChange",
    "You will receive a separate email outlining your full insurance policy from Manulife. Please check your inbox or spam folder for this information from confirmations@igoinsured.com": "You will receive a separate email outlining your full insurance policy from Manulife. Please check your inbox or spam folder for this information from confirmations@igoinsured.com",
    "You have declined travel Insurance. It is strongly recommended that you purchase travel insurance before you depart for your trip. Emergencies can occur anywhere. Travel insurance protects you against unforeseen circumstances, offering you a worry free travel experience. For more information on insurance options please contact us at 1.866.873.3824.": "You have declined travel Insurance. It is strongly recommended that you purchase travel insurance before you depart for your trip. Emergencies can occur anywhere. Travel insurance protects you against unforeseen circumstances, offering you a worry free travel experience. For more information on insurance options please contact us at 1.866.873.3824.",
    "Your travel insurance booking is currently processing.You can call us at 1.866.873.3824 to resolve the issue.": "Your travel insurance booking is currently processing.You can call us at 1.866.873.3824 to resolve the issue.",
    "Pending": "Pending",
    "Confirmed": "Confirmed",
    "Your travel insurance is confirmed": "Your travel insurance is confirmed",
    "Your travel insurance is currently Pending": "Your travel insurance is currently Pending",
    " Insurance Total": " Insurance Total",
    "Select Fare": "Select Fare",
    "Coupon code is applied successfully": "Coupon code is applied successfully",
    "Paid not ticketed": "Paid not ticketed",
    "Basic": "Basic",
    "Stops": "Stops",
    "FREE_BAGGAGES": "FREE_BAGGAGES",
    "Fee for Additional Bag": "Fee for Additional Bag ",
    "Flexible Payment Option":"Flexible Payment Option",
    "Pay in Full amount of":"Pay in Full amount of ",
    "Call Us":"Call Us",
    "Non Stop":"Non Stop",
    "ROUNDTRIP": "Round Trip",
    "ONEWAY": "One Way",
    "MULTICITY": "Multi-Destinations",
    "mo":"mo",
    "Why book with": "Why book with redtag.ca",
    "Secure Shopping": "Secure Shopping",
    "Privacy Protection": "Privacy Protection",
    "Exceptional Customer Service": "Exceptional Customer Service",
    "Our checkout": "Our checkout meets the credit card industry’s rigorous PCI security standard",
    "Your privacy": "Your privacy is always our number-one priority.",
    "Have questions": "Have questions before you check out? We’re here to help!",
    "Available Seat": "Available Seat",
    "Selected Seat": "Selected Seat",
    "Chargeable Seat": "Chargeable Seat",
    "Unavailable": "Unavailable",
    "Lavatory": "Lavatory",
    "Galley": "Galley",
    "Closet": "Closet",
    "Province of Residence :":`Province of Residence : The province of "NU" is restricted for this agency. Please contact your Travel Agent for more information. If you are a Travel Agent, please contact your Manager or your Manulife Sales Support Representative for more information.`,
    "Prepaid seats must be booked with the carrier":"Prepaid seats must be booked with the carrier",
    "Support available": "Support available, whenever you need",
    "Fastest & cheapest flights": "Fastest & cheapest flights",
    "Secured Transmission": "Secured Transmission",
    "Best Price Guaranteed": "Best Price Guaranteed",
    "Encrypted Storage": "Encrypted Storage",
    "24/7": "24/7",
    "An adult must be at least 12 years old. Please enter valid DOB or relaunch your search considering this passenger as a "
        :"An adult must be at least 12 years old. Please enter valid DOB or relaunch your search considering this passenger as a ",
    "A child must be 2 to 11 years old. Please enter valid DOB or relaunch your search considering this passenger as an"
        :"A child must be 2 to 11 years old. Please enter valid DOB or relaunch your search considering this passenger as an",
    "A infant must be less than 2 years old. Please relaunch your search considering this passenger as an"
        :"A infant must be less than 2 years old. Please relaunch your search considering this passenger as an",
    "Infant date of birth must be prior to booking date":"Infant date of birth must be prior to booking date",
    "Due to regulation for the province you've selected, please contact Manulife to obtain an insurance quote at 1-866-573-3824."
    :"Due to regulation for the province you've selected, please contact Manulife to obtain an insurance quote at 1-866-573-3824.",
    "Please provide Agency Code RT500 when you call.":"Please provide Agency Code RT500 when you call.",
    "Hours of Operation are as follows :":"Hours of Operation are as follows :",
    "Monday – Friday : 8AM – 8PM EST":"Monday – Friday : 8AM – 8PM EST",
    "Saturdays: 10AM – 5PM EST":"Saturdays: 10AM – 5PM EST",
    "Friday Summer Hours (Victoria Day to Labour Day): 8AM – 6PM EST":"Friday Summer Hours (Victoria Day to Labour Day): 8AM – 6PM EST",
    "Sunday & Stat holidays – Voicemail – Calls are returned within 24 – 48 hours":"Sunday & Stat holidays – Voicemail – Calls are returned within 24 – 48 hours"
}