export const frenchMessages = {
    // Search Page
    "Booking": "Réservation",
    "Confirmation": "Confirmation",
    "Round Trip": "Aller-retour",
    "One Way": "Aller-simple",
    "Multi City": "Multi-trajets",
    "Economy Class": "Classe économie ",
    "Premium Economy": "Économie supérieure",
    "Business Class": "Classe affaires ",
    "First Class": "Première classe",
    "Economy": "Économie",
    "Premium": "Supérieure",
    "Business": "Affaires",
    "First": "Première",
    "Departure dates": "Dates de départ",
    "Departure and return dates": "Dates de départ et de retour",
    "Adults": "Adultes",
    "Adult": "Adulte",
    "Children": "Enfants",
    "Search": "Recherche",
    "Filter": "Filtre",
    "Clear All": "Effacer",
    "From": "Du",
    "to": "à",
    "Airlines": "Transporteurs",
    "Email address": "Adresse courriel",
    "Operated by": "Opéré par",
    "or from": "ou de",
    "Flight Details": "Détails de l’itinéraire",
    "Add Flight": "Ajouter Le Vol",
    "fare": "tarif",
    "Layover": "Escale",
    "Price Range": "Échelle des prix",
    "Select Flight": "Sélectionnez un vol",
    "Departing From": "Au départ de",
    "Departing from": "Au départ de",
    "Going To": "À destination de",
    "No options": "Aucune option",
    "Best Departing Flights": "Meilleurs vols de départ",
    "Best Returning Flights": "Meilleurs vols de retour",
    "Best": "Meilleurs",
    "Departing": " de départ",
    "Flights": "vols",
    "Ranked based on price and convenience": "Classés selon le prix et la commodité",
    "Recommended": "Recommandé",
    "Stop": "Escales",
    "stop": "escale",
    "1 Stop": "1 escale",
    "2+ Stop": "2+ escales",
    "NonStop": "Sans escale",
    "Non-stop": "Sans escale",
    "Only": "Seulement",
    "Stay updated with the latest travel deals": "Restez informé des dernières offres de voyage",
    "Get Updates": "Recevoir les mises à jour",
    "SEGMENT": "SEGMENT",
    "Enter date": "Saisissez la date",
    "Remove Last Flight": "Retirer Le Dernier Vol",
    "in": "à",
    "minimumValue": "Valeur minimale",
    "maximumValue": "Valeur maximale",
    "count": "Nombre",
    "Baggage Allowance": "Franchise de bagages",
    "Clear": "Effacer",
    "Timings": "Horaires",
    "Take-off from": "Décollage de",
    "Travel Time": "Durée du voyage",
    "Flight Duration": "Durée du vol",
    "Layover Airports": "Aéroports d'escale",
    "Airports": "Aéroports",
    "Help us improve your booking experience": "Aidez-nous à améliorer votre expérience de réservation",
    "Give feedback": "Donnez votre avis",
    "hr": "hr",
    "min": "min",
    "Check-In Bag Not Included": "Bagage en Soute Non Inclus",
    "Landing at": "Atterrissage à",

    // Branded fares
    "Cabin": "Cabine",
    "Bags": "Bagages",
    "PremiumEconomy": "ÉconomieSupérieure",
    "BusinessClass": "AffairesClasse",
    "FirstClass": "PremièreClasse",
    "Select your fare": "Choisissez votre tarif",
    "Flexibility": "Flexibilité",
    "Select": "Sélectionner",
    "Seat": "Siège",
    "Priority": "Priorité",
    "BAGS": "BAGAGES",
    "CHECKED BAGS INCLUDED": "BAGAGES ENREGISTRÉS INCLUS",
    "FEE FOR ADDITIONAL BAG": "FRAIS POUR BAGAGE SUPPLÉMENTAIRE",
    "1 CABIN BAG UP TO 8KG": "1 BAGAGE CABINE JUSQU’À 8 KG",
    "2 CABIN BAGS UP TO 8KG": "2 BAGAGES CABINE JUSQU’À 8 KG",
    "SEAT": "SIÈGE",
    "SEAT CHOICE": "CHOIX DE SIÈGE",
    "BASIC SEAT": "SIÈGE DE BASE",
    "EXTRA LEG ROOM": "SIÈGE AVEC ESPACE SUPPLÉMENTAIRE POUR LES JAMBES",
    "STANDARD AND PREFERRED SEATS": "SIÈGES STANDARD ET PRÉFÉRÉS",
    "STANDARD SEAT RESERVATION": "RÉSERVATION DE SIÈGE STANDARD",
    "PREFERRED SEAT RESERVATION": "RÉSERVATION DE SIÈGE PRÉFÉRÉ",
    "Changes & Refund": "Modifications et Remboursements",
    "REFUNDS": "REMBOURSEMENTS",
    "CHANGES": "MODIFICATIONS",
    "CHANGE BEFORE DEPARTURE": "MODIFICATION AVANT LE DÉPART",
    "CHANGE AFTER DEPARTURE": "MODIFICATION APRÈS LE DÉPART",
    "REFUND BEFORE DEPARTURE": "REMBOURSEMENT AVANT LE DÉPART",
    "REFUND AFTER DEPARTURE": "REMBOURSEMENT APRÈS LE DÉPART",
    "SAME DAY CHANGE": "MODIFICATION LE JOUR MÊME",
    "CHANGEABLE TICKET": "BILLET MODIFIABLE",
    "REFUNDABLE TICKET": "BILLET REMBOURSABLE",
    "PRIORITY": "PRIORITÉ",
    "PRIORITY BAGGAGE": "BAGAGES PRIORITAIRES",
    "PRIORITY CHECK IN": "ENREGISTREMENT PRIORITAIRE",
    "PRIORITY SECURITY": "SÉCURITÉ PRIORITAIRE",
    "PRIORITY BOARDING": "EMBARQUEMENT PRIORITAIRE",
    "FAST TRACK SECURITY": "SÉCURITÉ AVEC ACCÈS RAPIDE",
    "DEDICATED CHECK IN ZONE": "ZONE D’ENREGISTREMENT DÉDIÉE",
    "SABRE_NDC BRAND FEATURES": "ABC",
    "CHECK 2 BAGS FREE": "ENREGISTREZ 2 BAGAGES GRATUITEMENT",
    "CHOOSE A LIE-FLAT SEAT": "CHOISISSEZ UN SIÈGE INCLINABLE À PLAT",
    "PRIORITY CHECK-IN AND BOARDING ACCESS (GROUPS 1-2)": "ACCÈS PRIORITAIRE À L’ENREGISTREMENT ET À L’EMBARQUEMENT (GROUPES 1-2)",
    "FLAGSHIP LOUNGE AND ADMIRALS CLUB ACCESS": "ACCÈS AU FLAGSHIP LOUNGE ET ADMIRALS CLUB",
    "CHANGES ALLOWED": "MODIFICATIONS AUTORISÉES",
    "FULL REFUND TO ORIGINAL FORM OF PAYMENT": "REMBOURSEMENT COMPLET SUR LE MODE DE PAIEMENT INITIAL",
    "IF YOU'RE AN AADVANTAGE® STATUS MEMBER OR AADVANTAGE® CREDIT CARDHOLDER, YOUR BAG BENEFIT AND BOARDING GROUP APPLIES IF HIGHER BASED ON STATUS. BENEFITS MAY NOT APPLY ON PARTNER AIRLINES.": "SI VOUS ÊTES UN MEMBRE AADVANTAGE® OU TITULAIRE D’UNE CARTE DE CRÉDIT AADVANTAGE®, VOTRE AVANTAGE DE BAGAGE ET VOTRE GROUPE D’EMBARQUEMENT S’APPLIQUENT SI PLUS AVANTAGEUX EN FONCTION DE VOTRE STATUT. LES AVANTAGES PEUVENT NE PAS S’APPLIQUER SUR LES COMPAGNIES PARTENAIRES.",
    "IF YOU'RE AN AADVANTAGE® CREDIT CARDHOLDER, YOUR BAG BENEFIT APPLIES ONLY ON DOMESTIC AMERICAN AIRLINES MARKETED AND OPERATED TRIPS.": "SI VOUS ÊTES TITULAIRE D’UNE CARTE DE CRÉDIT AADVANTAGE®, VOTRE AVANTAGE DE BAGAGE S’APPLIQUE UNIQUEMENT SUR LES TRAJETS INTÉRIEURS COMMERCIALISÉS ET OPÉRÉS PAR AMERICAN AIRLINES.",
    "AADVANTAGE® MEMBERS TRAVELING WITHIN THE U.S. CAN CANCEL BASIC ECONOMY FARE TICKETS ON AA.COM OR AMERICAN APP FOR A TRAVEL CREDIT, LESS A BASIC ECONOMY CANCELLATION FEE.": "LES MEMBRES AADVANTAGE® VOYAGEANT AUX ÉTATS-UNIS PEUVENT ANNULER DES BILLETS EN CLASSE BASIC ECONOMY SUR AA.COM OU L’APPLICATION AMERICAN POUR UN CRÉDIT VOYAGE, MOINS DES FRAIS D’ANNULATION BASIC ECONOMY.",
    "CHOOSE A MAIN CABIN SEAT": "CHOISISSEZ UN SIÈGE EN CABINE PRINCIPALE",
    "PAY FOR AN EXTRA LEGROOM SEAT": "PAYEZ POUR UN SIÈGE AVEC ESPACE SUPPLÉMENTAIRE POUR LES JAMBES",
    "BOARD IN GENERAL GROUP (6-8)": "EMBARQUEZ DANS LES GROUPES GÉNÉRAUX (6-8)",
    "DESCRIPTIONS": "DESCRIPTIONS",

    // Payment Confirmation
    "Title": "Titre",
    "None": "Aucun",
    "MR": "M",
    "MS": "MME",
    "MRS": "MLLE",
    "First Name": "Prénom",
    "Middle Name": "Deuxième nom",
    "Last Name": "Nom de famille",
    "Date Of Birth": "Date De Naissance",
    "Day": "Jour",
    "Month": "Mois",
    "Year": "Année",
    "Gender": "Genre",
    "Male": "Masculin",
    "Female": "Féminin",
    "Email": "Adresse courriel",
    "Enter phone number": "Entrez le numéro de téléphone",
    "Frequent Flyer Program": "Programme de fidélisation",
    "Expiry Date": "Date d'expiration",
    "CVV Code": "Code CVV",
    "City": "Ville",
    "Flight": "Vol",
    "Price Summary": "Sommaire du prix",
    "Taxes and fees": "Taxes et frais",
    "Passenger": "Passagers",
    "selected": "sélectionné",
    "Total": "Total",
    "Traveller Details": "Détails Des Voyageurs",
    "Traveller": "Voyageurs",
    "Fare type": "Type de tarif",
    "of": "de",

    // Booking Confirmation
    "Departure date": "Date de départ",
    "Departure": "Départ",
    "Arrival":"Arrivée",
    "Return": "Aller-retour",
    "Not Available": "Indisponible",
    "Please scroll up to review":
        "Veuillez faire défiler vers le haut pour examiner les messages d'erreur et mettre à jour les données en conséquence.",
    "Please select the above checkbox to proceed.":
        "Veuillez sélectionner la case à cocher ci-dessus pour continuer.",
    "You are about to book an unaccompanied minor (UMNR).":
        "Vous êtes sur le point de réserver une mineure non accompagnée (UMNR). Vous devez contacter le transporteur pour vérifier si votre passager être accepté à bord et s'il y a des procédures obligatoires Cela doit être suivi.",
    "I agree to contact the airline.":
        "J'accepte de contacter la compagnie aérienne. Continuez et réservez.",
    "Remove": "Retirer",

    "Traveller Information": "Informations du voyageur",
    "Program Number": "Numéro du programme",
    "Protect Your Travel Investment with our Insurance Plans": "Protégez votre investissement de voyage avec nos plans d’assurance",
    "Coverage valid for Canadian residents only": "Couverture valable uniquement pour les résidents canadiens.",
    "Customize Your Booking": "Personnalisez votre réservation",
    "Choose seats": "Choisissez vos sièges",
    "Show Seatmap": "Afficher le plan des sièges",
    "Select Payment option": "Choisir une option de paiement",
    "Pay the full amount": "Payer le montant total",
    "Pay Monthly": "Payer mensuellement",
    "Payment Details": "Détails du paiement",
    "We Use Secure Transmission & Protect Your Personal Information": "Nous utilisons une transmission sécurisée et protégeons vos informations personnelles",
    "We accept all major credit cards": "Nous acceptons toutes les cartes de crédit principales",
    "Name On Card": "Nom sur la carte",
    "Credit Card Number": "Numéro de carte de crédit",
    "Billing Address": "Adresse de facturation",
    "Street Name And House No": "Nom de la rue et numéro de maison",
    "Country": "Pays",
    "State": "État",
    "Province": "Province",
    // "State / Province": "État / Province",
    "Postal Code": "Code postal",
    "I read and agree to redtag.ca's terms & conditions and the supplier’s terms & conditions.":
        "J’ai lu et j’accepte les conditions générales de redtag.ca et du fournisseur.",
    "Complete Purchase": "Finaliser l'achat",
    "Full Flight Details": "Détails complets du vol",
    "Flight Price": "Prix du vol",
    "Base price": "Prix de base",
    "Total price": "Prix total",
    "Full price breakdown": "Détail complet du prix",
    "Confirmation will be sent here": "La confirmation sera envoyée ici",
    "Seat selected": "Siège sélectionné",
    "Seatmap Key": "Légende du plan des sièges",
    "middle": "milieu",
    "Add Seat": "Ajouter un siège",
    "Skip": "Passer",
    "Halte": "Escale",
    "Filter & Sort by": "Filtrer et trier par",
    "Thank you for booking with": "Merci d'avoir réservé avec",
    "Your flight booking is currently": "Votre réservation de vol est actuellement",
    "You have declined travel insurance.": "Vous avez refusé l’assurance voyage.",
    "Important Information": "Informations importantes",
    "Please do not attempt to rebook as it may result in duplicating your booking error and payment. Prices and availability are subject to change and are not guaranteed until payment has been processed.":
        "Veuillez ne pas essayer de réserver à nouveau, car cela pourrait entraîner la duplication de votre réservation et du paiement. Les prix et la disponibilité sont susceptibles de changer et ne sont pas garantis tant que le paiement n'a pas été traité.",
    "Please ensure that all your booking details are correct and verify that all passenger names are exactly as they appear on your valid passport(s).":
        "Veuillez vérifier que tous les détails de votre réservation sont corrects et que tous les noms des passagers correspondent exactement à ceux figurant sur vos passeports valides.",
    "Rates and availability are subject to change and are not guaranteed until payment has been processed. Call us at 1.866.573.3824 if you have any questions.":
        "Les tarifs et la disponibilité sont sujets à changement et ne sont pas garantis tant que le paiement n'a pas été traité. Appelez-nous au 1.866.573.3824 si vous avez des questions.",
    "You are responsible for reconfirming your flight times with Air Canada at least 24 hours prior to your flight departure via their website, by calling them or check with your local airport authority. You must check in at least 3 hours prior to your flight departure.":
        "Vous êtes responsable de confirmer les horaires de votre vol auprès de la compagnie aérienne au moins 24 heures avant le départ, soit en consultant leur site web, soit en les appelant, soit en vous renseignant auprès de l'autorité aéroportuaire locale. En outre, vous devez vous enregistrer au moins 3 heures avant le départ de votre vol.",
    "Terms Of Booking": "Conditions de réservation",
    "Your itinerary consists of two one-way fares which are subjects to their own rules and restrictions. If one of your flights is changed or canceled, it will not automatically change the other flight. You may incur a penalty free for each flight for additional itinerary changes.":
        "Votre itinéraire se compose de deux tarifs aller simple soumis à leurs propres règles et restrictions. Si l'un de vos vols est modifié ou annulé, cela ne changera pas automatiquement l'autre vol. Des frais de pénalité peuvent être appliqués pour chaque vol en cas de changements supplémentaires.",
    "Taxes and fees are included in the total ticket costs. Prices may not include (baggage fee) or other fees charged directly by the airline. Fares are not guaranteed until ticketed. Service fees are non-refundable. Cancellations, routing and date changes are subject to (fare rules) and our (fees), should these changes be allowed by the airline.":
        "Les taxes et frais sont inclus dans le coût total des billets. Les prix peuvent ne pas inclure (frais de bagages) ou autres frais facturés directement par la compagnie aérienne. Les tarifs ne sont pas garantis tant que le billet n’est pas émis. Les frais de service ne sont pas remboursables. Les annulations, changements d’itinéraire et de date sont soumis aux (règles tarifaires) et à nos (frais), si ces changements sont autorisés par la compagnie aérienne.",
    "Travel Insurance Details": "Détails de l’assurance voyage",
    "Manulife Policy": "Police Manuvie",
    "DECLINED": "REFUSÉE",
    "You Have DECLINED Travel Insurance. Travel insurance is designed to offer you a worry free travelling experience. Emergencies can occur anywhere. Travel insurance protects you against unforseen circumstances.":
        "Vous avez REFUSÉ l’assurance voyage. L’assurance voyage est conçue pour vous offrir une expérience de voyage sans souci. Les urgences peuvent survenir n’importe où. L’assurance voyage vous protège contre les imprévus.",
    "Billing Details": "Détails de facturation",
    "Supplier": "Fournisseur",
    "Booking Code": "Code de réservation",
    "Paid - Not Ticketed": "Payé - Non émis",
    "Full Name": "Nom complet",
    "Credit Card Holder": "Titulaire de la carte de crédit",
    "Card Type": "Type de carte",
    "Payment Total": "Montant total",
    "Payment Method": "Méthode de paiement",
    " Insurance:": " Assurance:",
    "You have applied too many filters.": "Vous avez appliqué trop de filtres.",
    "Click": "Cliquez",
    "here": "ici",
    "to View all results": "pour voir tous les résultats.",
    "Interest-free payments also available":
        "Paiements sans intérêt également disponibles",
    "Pay monthly with": "Payer mensuellement avec",
    "Something went wrong": "Quelque chose s'est mal passé",
    "Child's age upon return": "Âge de l’enfant au retour",
    "Multiple flights": "Vols multiples",
    "Multiple Flight Details":"Vols multiples détails",
    "For Coverage amounts click": "Pour les montants de couverture cliquez",
    "Show Less": "Afficher moins",
    "Show More": "Afficher plus",
    "per day": "par jour",
    "Starting from": "À partir de",
    "per person": "par personne",
    "Enter a valid mobile number": "Saisissez un numéro de mobile valide",
    "Your Booking": "Votre réservation",
    "is processing please wait": "est en cours de traitement, veuillez patienter",
    "Woops": "Oups",
    "Someting went wrong": "Quelque chose s'est mal passé",
    "There was a problem processing the request": "Un problème est survenu lors du traitement de la demande",
    "Go to Home": "Aller à l'accueil",
    "Oops! Page not found": "Oups ! Page introuvable.",
    "The page you are looking for was not found.": "La page que vous recherchez est introuvable.",
    "Select a flight": "Sélectionnez un vol",
    "Passengers with same name are not allowed": "Les passagers avec le même nom ne sont pas autorisés",
    "I read and agree to redtag.ca's": "J'ai lu et j'accepte les",
    "terms & conditions": "termes et conditions",
    "and the supplier’s": "de redtag.ca ainsi que les ",
    "du fournisseur":" du fournisseur",
    "Add More": "Ajouter plus",
    "As per your Passport": "Tel qu’indiqué sur votre passeport",
    "Mobile Number": "Numéro de mobile",
    "Facilities": "Installations",
    "Locations": "Emplacements",
    "Characteristics": "Caractéristiques",
    "Limitations": "Limitations",
    "with": "avec",
    "Change Flight": "Changer la vol",
    "Address": "Adresse",
    "Postal": "Postal",
    "Infant": "Bébé",
    "Child": "Enfant",
    "Invalid": "Invalide",
    "Name": "Nom",
    "Card Number": "Numéro de carte",
    "Select Seat": "Sélectionner un Siège",
    "Pay monthly from": "Paiement Mensuel à Partir de",
    "Add Insurance": "Ajouter une Assurance",
    "Unable to retrieve seat map. Please try again": "Impossible de récupérer le plan des sièges. Veuillez réessayer.",
    "Add Gift Card": "Ajouter une Carte-Cadeau",
    "Important": "Important",
    "Online redemption only valid for cards purchased after": "Utilisation en ligne valable uniquement pour les cartes achetées après le",
    "please contact a redtag agent to redeem cards issued prior to that date": "veuillez contacter un agent de redtag pour utiliser les cartes émises avant cette date.",
    "Add More Gift Cards": "Ajouter Plus de Cartes-Cadeaux",
    "Gift Card": "Carte-Cadeau",
    "Gift Card Number": "Numéro de la Carte-Cadeau",
    "Digit Pin": "Code PIN",
    "Apply": "Appliquer",
    "Flexible payment options": "Options de Paiement Flexibles",
    "We share program details with the airline, though we cannot guarantee point awards": "Nous partageons les détails du programme avec la compagnie aérienne, mais nous ne pouvons pas garantir l’attribution des points.",
    "Loading": "Chargement",
    "Available Balance": "Solde Disponible",
    "Booking Summary": "Résumé de Réservation",
    "Select different Outbounds": "Sélectionner des Départs Différents",
    "Returning": "Retour ",
    "Check-In Bag Included": "Bagage en Soute Inclus",
    "Zip Code": "Code Postal",
    // "Postal":	"Adresse Postale",
    "processing": "traitement",
    "Phone Number": "Numéro de Téléphone",
    "Enter Your Promo Code": "Saisissez Votre Code Promo",
    "Booked Seats Overview": "Aperçu des Sièges Réservés",
    "Travel Insurance": "Assurance Voyage",
    "Checked Bags Included": "Bagages En Soute Inclus",
    "Descriptions": "Descriptions",
    "Add": "Ajouter",
    "Apply a promo code": "Appliquer un Code Promo",
    "Your previous Promo has been removed successfully": "Votre Code Promo Précédent a été Supprimé avec Succès",
    "Please Enter Promocode.": "Veuillez Saisir un Code Promo",
    "It seems that there\'s an issue applying this Promo code to your booking. Please call": "Il semble y avoir un problème pour appliquer ce Code Promo à votre réservation. Veuillez appeler",
    "for assistance": "pour obtenir de l'aide",
    "Receive a $20 Amazon Gift Card with Flight Bookings": "Recevez une Carte-Cadeau Amazon de 20 $ pour les Réservations de Vol.",
    "Gift card applied successfully": "Carte-Cadeau Appliquée avec Succès",
    "Decline Insurance": "Refuser l’Assurance",
    "Street Address": "Adresse",
    "Payment": "Paiement",
    "Return Date": "Date de retour",
    "Destination": "Destination",
    "Sort by Price (Low to High)": "Trier par prix (le plus bas)",
    "Sort by Price (High to Low)": "Trier par prix (le plus élevé)",
    "Sort by Duration (Short to Long)": "Trier par durée (la plus courte)",
    "Sort by Duration (Long to Short)": "Trier par durée (la plus lounge)",
    "Fill required information": "Remplir les informations requises",
    "Pricing Failed!": "Échec de tarification!",
    "Booking Failed !": "Échec de réservation !",
    "Please enter gift card pin": "Veuillez entrer le code PIN de la carte-cadeau",
    "Please enter gift card number": "Veuillez entrer le numéro de la carte-cadeau",
    "PIN must be a 4-digit number": "Le PIN doit être un numéro à 4 chiffres",
    "Gift Card invalid or no funds available": "Carte-cadeau invalide ou fonds insuffisants",
    "Invalid Program Number": "Numéro de programme invalide",
    "provinceChange": "provinceChange",
    "You will receive a separate email outlining your full insurance policy from Manulife. Please check your inbox or spam folder for this information from confirmations@igoinsured.com": "Vous recevrez un courriel distinct détaillant votre police d'assurance complète de Manuvie. Veuillez vérifier votre boîte de réception ou votre dossier de spam pour ce message envoyé par confirmations@igoinsured.com",
    "You have declined travel Insurance. It is strongly recommended that you purchase travel insurance before you depart for your trip. Emergencies can occur anywhere. Travel insurance protects you against unforeseen circumstances, offering you a worry free travel experience. For more information on insurance options please contact us at 1.866.873.3824.": "Vous avez refusé l'assurance voyage. Il est fortement recommandé d'acheter une assurance voyage avant de partir pour votre voyage. Les urgences peuvent survenir n'importe où. L'assurance voyage vous protège contre les imprévus, vous offrant une expérience de voyage sans souci. Pour plus d'informations sur les options d'assurance, veuillez nous contacter au 1.866.873.3824.",
    "Your travel insurance booking is currently processing.You can call us at 1.866.873.3824 to resolve the issue.": "Votre réservation d'assurance voyage est en cours de traitement. Vous pouvez nous appeler au 1.866.873.3824 pour résoudre le problème.",
    "Pending": "En attente",
    "Confirmed": "Confirmé",
    "Your travel insurance is confirmed": "Votre assurance voyage est confirmée",
    "Your travel insurance is currently Pending": "Votre assurance voyage est actuellement en attente",
    " Insurance Total": " Total assurance",
    "Select Fare": "Sélectionnez le tarif",
    "Coupon code is applied successfully": "Le code promo a été appliqué avec succès",
    "Paid not ticketed": "Payé non émis",
    "Basic": "Basique",
    "Stops": "Escales",
    "FREE_BAGGAGES": "BAGAGES_GRATUITS",
    "Fee for Additional Bag": "Frais pour bagage supplémentaire ",
    "Insurance": "Assurance",
    "Flexible Payment Option":"Options de paiement flexible",
    "Pay in Full amount of":"Payer le montant total de ",
    "Call Us":"Contactez-nous",
    "Pay Monthly from":"Payez tous les moisde",
    "Powered by":"Optimisé par",
    "Non Stop":"Sans Escale",
    "ROUNDTRIP": "Aller-retour",
    "ONEWAY": "Aller-simple",
    "MULTICITY": "Multi-trajets",
    "mo":"mois",
    "Why book with": "Pourquoi réserver avec redtag.ca",
    "Secure Shopping": "Achat sécurisé",
    "Privacy Protection": "Service à la clientèle exceptionnel",
    "Exceptional Customer Service": "Service à la clientèle exceptionnel",
    "Our checkout": "Lorsque vous passez à la caisse, nous respectons les normes de sécurité rigoureuses des données du secteur des cartes de paiements (SCP) .",
    "Your privacy": "Votre vie privée est toujours notre priorité numéro un.",
    "Have questions": `Vous avez des questions avant de passer à la caisse ?
                        Nous sommes là pour vous aider!`,
    "Available Seat": "Siège disponible",
    "Selected Seat": "Siège sélectionné",
    "Chargeable Seat": "Siège payant",
    "Unavailable": "Non disponible",
    "Lavatory": "Toilette",
    "Galley": "Cuisine",
    "Closet": "Penderie",
    "Province of Residence :":` La province de « NU » est restreinte pour cette agence. Veuillez communiquer avec votre agent de voyages pour obtenir de plus amples renseignements. Si vous êtes un agent de voyages, veuillez communiquer avec votre responsable ou votre représentant du soutien aux ventes de Manuvie pour obtenir de plus amples renseignements.`,
    "Prepaid seats must be booked with the carrier":"Les sièges prépayés doivent être réservés auprès du transporteur.",
    "Support available": "Assistance disponible à tout moment,",
    "Fastest & cheapest flights": "Vols les plus rapides et les moins chers",
    "Secured Transmission": "Transmission sécurisée",
    "Best Price Guaranteed": "Garantie du meilleur prix",
    "Encrypted Storage": "Stockage crypté",
    "24/7": "24 h/24 et 7 j/7",
    "An adult must be at least 12 years old. Please enter valid DOB or relaunch your search considering this passenger as a "
        :"Un adulte doit avoir au moins 12 ans. Veuillez relancer votre recherche compte tenu de ce passager ",
    "A child must be 2 to 11 years old. Please enter valid DOB or relaunch your search considering this passenger as an"
        :"Un enfant doit avoir 2 à 11 ans. Veuillez relancer votre recherche en considérant ce passager en tant qu ",
    "A infant must be less than 2 years old. Please relaunch your search considering this passenger as an"
        :"L'âge du bébé doit être plus bas que 2 ans. Veuillez relancer votre recherche en considérant ce passager comme",
    "Infant date of birth must be prior to booking date":"Date de naissance du bébé doit être antérieure à la date de réservation.",
    "Due to regulation for the province you've selected, please contact Manulife to obtain an insurance quote at 1-866-573-3824."
    :"En raison de la réglementation en vigueur dans la province que vous avez choisie, veuillez communiquer avec Manuvie pour obtenir une soumission d'assurance au 1-866-573-3824.",
    "Please provide Agency Code RT500 when you call.":"Veuillez fournir le code d'agence RT500 lorsque vous appelez.",
    "Hours of Operation are as follows :":"Les heures d'ouverture sont les suivantes :",
    "Monday – Friday : 8AM – 8PM EST":"Du lundi au vendredi : 8 h - 20 h HNE",
    "Saturdays: 10AM – 5PM EST":"Samedi : DE 10 h À 17 h ( HNE)",
    "Friday Summer Hours (Victoria Day to Labour Day): 8AM – 6PM EST":"Heures d'été du vendredi (de la fête de Victoria (Journée nationale des patriotes au Québec) à la fête du Travail) : 8 h - 18 h HNE",
    "Sunday & Stat holidays – Voicemail – Calls are returned within 24 – 48 hours":"Dimanche et jours fériés - Messagerie vocale - Les appels sont retournés dans les 24 à 48 heures."
}