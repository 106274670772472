import { BookWithPaymentApi, GetCountriesAPI, GetStateAPI, PricingAPI, GetQuoteAPI, GetFrequentFlyerAPI, BookQuoteAPI, SeatMapAPI, GetGiftCardAPI, PreAuthGiftCard, PostAuthGiftCard, CancelPreAuth, GetPromoCode } from 'gigi-api-layer';
import { BookWithPayment, ModalComponent } from 'gigi-component-library';
import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setSegmentData, updateBookingData, updateFlowFrom } from '../../redux/actions/booking';

import { updateQuoteBookingData } from '../../redux/actions/quoteBooking';
import { updateGiftCardData } from '../../redux/actions/giftcardResult';
import { ROUTES, INSURANCESTR, paymentlabel } from '../../utility/constants';

import { updatePricingData } from '../../redux/actions/brandedFare';
import { ErrorNotifier, ErrorHandler } from 'gigi-component-library';

import { QuoteRequestTranslation } from './QuoteRequestTranslation';
const BookingComponent = () => {
    const searchResults = useSelector((state: any) => state.search.searchResults);
    const correlationId = useSelector((state: any) => state.search.correlationId);
    const brandedFareId = useSelector((state: any) => state.brandedFares.selectedBrandedFareId);
    const iteneraryFareSelected = useSelector((state:any)=> state.brandedFares.selectedItineraryFare)
    const triptype = useSelector((state: any) => state.search.tripType);
    const bookingAgency = useSelector((state: any) => state.languageChange.bookingAgency);
    const bookingAgent = useSelector((state: any) => state.languageChange.bookingAgent);
    const language = useSelector((state: any) => state.languageChange.language);
    const [pricingAPI, pricingResults, pricingInProgress, pricingError] = PricingAPI()
    const pricingData = useSelector((state: any) => state.brandedFares.pricingData);
    const segmentData = useSelector((state: any) => state.bookingResult.segment);
    const [bookWithPaymentAPI, bookingResult, bookingInProgress, bookingError] = BookWithPaymentApi();
    const [countriesAPI, countriesData, countriesInProgress, countriesError] = GetCountriesAPI()
    const [stateAPI, stateData, stateInProgress, stateError] = GetStateAPI();
    const [paymentMethod, setPaymentMethod] = useState(paymentlabel.FULLPAYMENT);
    const TripInfoData = useSelector((state: any) => state.tripInfoResult.uplift);
    const [redirectFunction, setRedirectFunction] = useState(() => { })
    const [getQuoteAPI, quoteResults, quoteInProgress, quoteError] = GetQuoteAPI();
    const [quoteRequestData, setQuoteRequestData] = useState<QuoteRequestTranslation | null>(null);
    const [version, setVersion] = useState(0);
    const [bookQuoteAPI, bookQuoteResults, bookQuoteInProgress, bookQuoteError] = BookQuoteAPI();
    const [paymentRequestData, setPaymentRequestData] = React.useState({});
    const [btnLoader, setBtnLoader] = useState(false)

    const [giftcardApi, giftCardData, giftCardLoding, error] = GetGiftCardAPI();
    const [preAuthGiftCardAPI, preAuthdata, preAuthGifrcardLoading, preAutherror] = PreAuthGiftCard();
    const [CancelAuthGiftCardAPI, cancelAuthdata, cancelAuthGifrcardLoading, cancelAutherror] = CancelPreAuth();
    const [PostAuthGiftCardAPI, postAuthdata, postAuthGifrcardLoading, postAutherror] = PostAuthGiftCard();

    const [giftCardResult, setgiftCardResult] = useState(null);
    const [postAuthRequest, setpostAuthRequest] = useState(null);
    const [cancelPreAuthRequest, setcancelPreAuthRequest] = useState(null);
    const [getFrequentFlyerApi, frequentFlyerData, frequentFlyerInProgress, frequentFlyerError] = GetFrequentFlyerAPI()
    const [getPromoCode, promoCodeResults, promoCodeInProgress, promoCodeError] = GetPromoCode();
    const [GiftcardPayload, setGiftcardPayload] = useState(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {PROMO_CONFIG = {}} = process.env;
    
    const handleApplyPromo = async (payload) => {
        getPromoCode(payload, correlationId)
    }

    const redirectToBookingConfirmation = () => {
        // let tOut = setTimeout(() => {
        //     clearTimeout(tOut);
            const queryParams = new URLSearchParams({ uid: correlationId });
            navigate(`${ROUTES.bookingConfirmation}?${queryParams.toString()}`);
        // }, 5000)
    }

    useEffect(() => {
        if (promoCodeResults) {
            setVersion((prevVersion) => prevVersion + 1);
        }
    }, [promoCodeResults]);

    useEffect(() => {
        if(pricingError){
            console.log("pricingError",pricingError)
            ErrorHandler('Pricing Failed!', "There is no response from the pricing API. You will be redirected to the search page. Please try again.", null, 'Ok', 'warning', 'alertModal');
            setTimeout(() => setRedirectFunction(redirect), 2000)
        }

    }, [pricingError])

    const handleQuoteUpdateRequest = (newRequest, actionStr) => {
        if (quoteRequestData) {
            if (actionStr == INSURANCESTR.provinceChange) {
                quoteRequestData.setProvince = newRequest;
            } else if (actionStr == INSURANCESTR.paxChange) {
                quoteRequestData.setPassengerData = newRequest;
            } else if (actionStr == INSURANCESTR.paxInsChange) {
                const { paxOptionArray, travellers } = newRequest;
                quoteRequestData.setPaxInsArray = paxOptionArray;
                quoteRequestData.setTravellerArray = travellers;
            }
            setQuoteRequestData(quoteRequestData);
            setVersion((prevVersion) => prevVersion + 1);

            if (actionStr != INSURANCESTR.paxInsChange)
                callQuoteAPI();
        }
    }

    useEffect(() => {
        if (countriesData) {

        }
    }, [countriesData]);

    useEffect(() => {
        if (!countriesData) {
            countriesAPI();
        }

        if (brandedFareId.fareID) {
            pricingAPI(brandedFareId.fareID, searchResults.token, correlationId);
        }

        if (window._satellite) {
            var digitalData = {
                path: "Flights",
                page: {
                pageID: 203,
                pageName: "flights:verify book",
                pageURL: window.location.href,
                referringURL: document.referrer,
                pageType: "verify",
                language: "en-US",
                platform: "Desktop",
                },
            };
            window._satellite.track("dom-ready", { digitalData: digitalData });
        }
    
        
    }, []);

    useEffect(() => {
        if (pricingResults) {
            if (!quoteResults) {
                let transPayload = new QuoteRequestTranslation(pricingResults);

                setQuoteRequestData(transPayload); // set the quote api request in state
                getQuoteAPI(transPayload, correlationId);
            }
            dispatch(updatePricingData(pricingResults));
        }
        if (pricingResults && (!pricingResults.hasOwnProperty('itineraryFare'))) {
            let timeout = 0;
            if (pricingResults?.error?.message) {
                timeout = 2000;
                ErrorHandler('Pricing Failed!', pricingResults?.error?.message, null, 'Ok', 'warning', 'alertModal')
            } else if (pricingResults?.error) {
                timeout = 2000;
                ErrorHandler('Pricing Failed!', pricingResults?.error, null, 'Ok', 'warning', 'alertModal')
            } else {
                timeout = 2000;
                ErrorHandler('Pricing Failed!', 'There is no response from the pricing API. You will be redirected to the search page. Please try again.', null, 'Ok', 'warning', 'alertModal')
            }
            setTimeout(() => setRedirectFunction(redirect), timeout)

        }
    }, [pricingResults])


    const translateGiftCardArray = (giftCards: Array<{
        number: string;
        pin?: string;
        balance: number;
        charge: number;
    }>) => {
        return giftCards.map(giftCard => ({
            sid: correlationId,
            lang: 'en',
            cardNumber: giftCard.number,
            pin: giftCard.pin,
            amount: giftCard.charge,
        }));
    };
    const redirect = useCallback(() => {
history.back()
    }, [])

    const handlePaymentMethod = (paymentType: string) => {
        setPaymentMethod(paymentType);
    }

    useEffect(() => {

        const processBooking = async () => {
            if (bookingResult) {
                if (!bookingResult?.error && !bookingResult?.code?.includes("ERR") && bookingResult?.payments?.length > 0) {
                    bookingResult.payments[0].paymentMethod = paymentMethod;
                }
                dispatch(updateBookingData(bookingResult));
                const paxInsData = quoteRequestData.getPaxInsArray;
                const insuranceAmount = paxInsData.reduce((sum, option) => sum + Number(option.amount), 0);

                setBtnLoader(true)
                if (bookingResult && bookingResult.hasOwnProperty('recordLocator')) {
                    dispatch(updateFlowFrom("booking"));

                    // if (preAuthStatus) {
                    //     PostAuthGiftCardAPI(postAuthRequest);
                    // }
                    // if (postAuthRequest) {
                    //     await Promise.all(
                    //         postAuthRequest.map(async (card) => {
                    //             const postAPIResponse = await PostAuthGiftCardAPI(card, correlationId);
                    //             return postAPIResponse;
                    //         })
                    //     );
                    // }

                    //Book Quote Api call if insurance selected
                    // if (insuranceAmount > 0) {
                    //     let newBookQuoteResult = {
                    //         ...bookingResult,
                    //         "bookRequest": paymentRequestData
                    //     }
                    //     quoteRequestData.setBookQuoteRequest = newBookQuoteResult;
                    //     setQuoteRequestData(quoteRequestData);
                    //     const bookQuoteApiResponse = await bookQuoteApifn();
                    // } else {
                        dispatch(updateQuoteBookingData(quoteRequestData));
                        dispatch(updateGiftCardData(GiftcardPayload));
                        setVersion((prevVersion) => prevVersion + 1);
                        redirectToBookingConfirmation();
                    // }

                } else {
                    // if (cancelPreAuthRequest && GiftcardPayload.length > 0) {
                    //     handleCancelPreAuth(cancelPreAuthRequest);
                    // }

                    if (bookingResult?.accertifyScore && bookingResult.accertifyScore > 50) {
                        // setRedirectFunction(redirect)
                        ErrorHandler('Booking Failed !','Technical error occur. Please contact us to make this booking', null, 'Ok', 'warning', 'alertModal');
                    } else if (bookingResult?.error?.message) {
                        ErrorHandler('Booking Failed !', bookingResult?.error?.message, null, 'Ok', 'warning', 'alertModal')
                    } else if (bookingResult?.message) {
                        ErrorHandler('Booking Failed !', bookingResult?.message, null, 'Ok', 'warning', 'alertModal')
                    } else {
                        ErrorHandler('Booking Failed !', bookingResult?.error || 'Technical error occur. Please contact us to make this booking', null, 'Ok', 'warning', 'alertModal');
                    }
                }
            }
        }
        processBooking();
    }, [bookingResult]);

    const bookQuoteApifn = async () => {
        try {
            await bookQuoteAPI(quoteRequestData.getBookQuoteRequest, correlationId);
            //   setVersion((prevVersion) => prevVersion + 1);
            return 1; // Return the API response
        } catch (error) {
            console.error("Error in bookQuoteApifnct:", error);
            return null; // Handle error by returning null or a specific error response
        }
    };

    function callQuoteAPI() {
        if (quoteRequestData) {
            getQuoteAPI(quoteRequestData);
        }
    }

    const navigateToSearch = () => {
        history.back()
    }

    // const handleBookWithPaymentAPIwithGiftCard = async (payload, setLoader) => {
    //     const { giftCard, priceData } = payload;
    //     const PaymentMethod = payload.payments[0].paymentMethod;

    //     try {
    //         if (PaymentMethod !== 'upliftpayment') {
    //             setGiftcardPayload(giftCard);

    //             const postGiftCardReqArray = [];
    //             const cancelGiftCardArray = [];
    //             const tempCancelGIftcardArray = [];

    //             if (priceData.giftCardTotal > 0) {
    //                 const translateData = translateGiftCardArray(giftCard);

    //                 const allResponses = await Promise.all(
    //                 translateData.map(async (card) => {
    //                     const APIResponse = await preAuthGiftCardAPI(card, correlationId);
    //                     if (APIResponse.response.status === 'success') {
    //                     tempCancelGIftcardArray.push(card);
    //                     } else {
    //                     ErrorHandler('Booking Failed!', 'Gift card processing error. Please contact support.', null, 'Ok', 'warning', 'alertModal');
    //                     if (tempCancelGIftcardArray.length > 0) {
    //                         handleCancelPreAuth(tempCancelGIftcardArray);
    //                     }
    //                     setLoader(false);
    //                     return null;
    //                     }
    //                     return APIResponse;
    //                 })
    //                 );

    //                 allResponses.forEach(({ postGiftCardReq }) => {
    //                 postGiftCardReqArray.push(postGiftCardReq);
    //                 const { amount, ...withoutAmount } = postGiftCardReq;
    //                 cancelGiftCardArray.push(withoutAmount);
    //                 });

    //                 setpostAuthRequest(postGiftCardReqArray);
    //                 setcancelPreAuthRequest(cancelGiftCardArray);
    //             }
    //         }
    //         // delete payload.giftCard;
    //         // delete payload.priceData;
    //         if (priceData.giftCardTotal < priceData.itineryTotal && quoteRequestData) {  
    //             let newPaymeRequest = {
    //                 ...payload,
    //                 payments: payload.payments.map(payment => ({
    //                     ...payment,
    //                     CreditCard: { ...payment.CreditCard }
    //                 }))
    //             };

    //             let provinceInReq = newPaymeRequest.payments[0].CreditCard.province;
    //             if(stateData){
    //                 const StateCode = stateData.states.find(province => province.name === provinceInReq)?.state_code;
    //                 newPaymeRequest.payments[0].CreditCard.province = StateCode;
    //             }
    //             setPaymentRequestData(newPaymeRequest.payments[0].CreditCard);
    //         }

    //         if (PaymentMethod === 'upliftpayment' && !Object.keys(payload.payments[0].CreditCard).length) {
    //         ErrorHandler('Required Information', 'Please fill all required fields for Uplift Payment.', null, 'Ok', 'warning', 'alertModal');
    //         setLoader(false);
    //         return;
    //         }

    //         await bookWithPaymentAPI(payload, pricingData.token, correlationId);
    //         setLoader(false);
            
    //     } catch (error) {
    //         console.error("Error during booking:", error.message);
    //         setLoader(false);
    //     }
    // };

    const handleBookWithPaymentAPIwithGiftCard = async (payload, setLoader) => {
        const {giftCard,priceData } = payload;
        const PaymentMethod = payload?.payments?.[0]?.paymentMethod;

        try {
            setGiftcardPayload(giftCard);
            if (priceData.giftCardTotal < priceData.itineryTotal && quoteRequestData) {  
                let newPaymeRequest = {
                    ...payload,
                    payments: payload.payments.map(payment => ({
                        ...payment,
                        CreditCard: { ...payment.CreditCard }
                    }))
                };

                let provinceInReq = newPaymeRequest.payments[0].CreditCard.province;
                if(stateData){
                    const StateCode = stateData.states.find(province => province.name === provinceInReq)?.state_code;
                    newPaymeRequest.payments[0].CreditCard.province = StateCode;
                }
                setPaymentRequestData(newPaymeRequest.payments[0].CreditCard);
            }

            if (PaymentMethod === 'upliftpayment' && !Object.keys(payload.payments[0].CreditCard).length) {
            ErrorHandler('Required Information', 'Please fill all required fields for Uplift Payment', null, 'Ok', 'warning', 'alertModal');
            setLoader(false);
            return;
            }
            payload.segment=segmentData;
            payload.language=language;
            await bookWithPaymentAPI(payload, pricingData.token, correlationId);
            // let tOut = setTimeout(() => {
            //     clearTimeout(tOut);
                setLoader(false);
            // }, 5000)
            
            
        } catch (error) {
            console.error("Error during booking:", error.message);
            setLoader(false);
        }
    };



    const handleCancelPreAuth = async (cancelData) => {

        if (cancelData) {
            await Promise.all(
                cancelData.map(async (card) => {
                    const APIResponse = await CancelAuthGiftCardAPI(card, correlationId);
                    return APIResponse;
                })
            );
        }
    }
    useEffect(() => {
        if (quoteResults) {
            quoteRequestData.setQuoteData = quoteResults;
            setQuoteRequestData(quoteRequestData);
            setVersion((prevVersion) => prevVersion + 1);
        }
    }, [quoteResults]);

    useEffect(() => {
        if (giftCardData?.response) {
            setgiftCardResult(giftCardData.response);
            setVersion((prevVersion) => prevVersion + 1);
        }
    }, [giftCardData])



    useEffect(() => {
        if (pricingResults?.segments) {
            let combineFlightData = [];
            let combineCarrierName = [];
            dispatch(setSegmentData(pricingResults?.segments));
            pricingResults?.segments.forEach(segment => {
                segment.flights.forEach(flight => {
                    combineFlightData.push(flight);
                    if (!combineCarrierName.includes(flight.operatingCarrier)) {
                        combineCarrierName.push(flight.operatingCarrier);
                    }
                });
            });

            if (combineCarrierName.length > 0) {
                getFrequentFlyerApi({ carriers: combineCarrierName }, correlationId);
            }
        }
    }, [pricingResults]);

    useEffect(() => {
        if (bookQuoteResults) {
            let newBookQuoteResults = { ...bookQuoteResults, quoteTranslation: quoteRequestData }
            dispatch(updateQuoteBookingData(newBookQuoteResults));
            setVersion((prevVersion) => prevVersion + 1);
            redirectToBookingConfirmation();
        }
    }, [bookQuoteResults]);
 
    // console.log("quoteRequestData",quoteRequestData)
    return (

        <div className='wrapper'>
            <BookWithPayment handlePaymentMethod={handlePaymentMethod} paymentMethod={paymentMethod} countriesData={countriesData} stateAPI={stateAPI} stateData={stateData} pricingData={pricingResults} pricingInProgress={pricingInProgress} bookWithPaymentAPI={handleBookWithPaymentAPIwithGiftCard} GiftCardAPI={((payload) => giftcardApi(payload, correlationId))} giftCardResponse={giftCardResult} giftCardLoding={giftCardLoding} updateQuoteRequest={handleQuoteUpdateRequest} quoteRequest={quoteRequestData} quoteInProgress={quoteInProgress} triptype={triptype} TripInfoData={TripInfoData} getFrequentFlyerApi={(payload) => { getFrequentFlyerApi(payload, correlationId) }} frequentFlyerData={frequentFlyerData?.ffAgreementCarriers} btnLoader={btnLoader} token={searchResults?.token ?? ''} SeatMapAPI={SeatMapAPI} navigateToSearch={navigateToSearch} correlationId={correlationId} promoconfig={PROMO_CONFIG} callPromoCodeApI={handleApplyPromo} promoCodeResults={promoCodeResults} promoCodeInProgress={promoCodeInProgress} iteneraryFareSelected={iteneraryFareSelected} bookingAgency={bookingAgency}  bookingAgent={bookingAgent} language={language}/>
            <ErrorNotifier handleCloseFunc={redirectFunction} />
        </div>
    );
};

export default BookingComponent;