import React, { useEffect, useState } from 'react';
import { Confirmation, BookingSummarySkeleton } from 'gigi-component-library';
import { useDispatch, useSelector } from 'react-redux';
import { signIn } from "gigi-api-layer";
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../utility/constants';
import { BookingDetailsAPI, FareRulesforPNRAPI } from 'gigi-api-layer';
import { SegmentConverter } from './segmentTranslation';

const BookingConfirmationComponent = () => {
    // const dispatch = useDispatch();
    // const [callSignInAPI, signInData, signInProgress, signInError]= signIn()
    const navigate = useNavigate();
    const bookingData = useSelector((state: any) => state.bookingResult.booking);
    const correlationId = useSelector((state: any) => state.search.correlationId);
    let quoteBookingData = useSelector((state: any) => state.quoteBookingResult.quoteBooking);
    let giftCardPayload = useSelector((state: any) => state.giftCardResult.giftCard);
    let recordLocator= bookingData?.recordLocator;

    const bookingAgency = useSelector((state: any) => state.languageChange.bookingAgency);
    const bookingAgent = useSelector((state: any) => state.languageChange.bookingAgent);
    const flowFrom = useSelector((state: any) => state.bookingResult.flowFrom);
    const segmentData = useSelector((state: any) => state.bookingResult.segment);
    const language = useSelector((state: any) => state.languageChange.language);
    
    const PaymentMethod = !bookingData?.error && bookingData?.payments?.[0] ? bookingData?.payments?.[0]?.paymentMethod:'';

    // useEffect(()=>{
    //     if(signInData && signInData.hasOwnProperty('accessToken') ){
    //         navigate(ROUTES.searchWidgetPage)
    //     }
    // },[signInData])
    const [callGetBookingDetails, getBookingDetailsData, getBookingDetailsInProgress, getBookingDetailsError]= BookingDetailsAPI()
    const [faresRulesForPNR, fareRulesForPNRData, fareRulesForPNRInProgress, fareRulesForPNRError] = FareRulesforPNRAPI()
    const [segmentsData, setSegmentsData] = useState([])

    useEffect(() => {
        if(bookingData){
            if (flowFrom !== "booking") {
                fetchBookingDetails();
            }else{
                const seg = new SegmentConverter(segmentData);
                const convertedResponse = seg.convertSegments();
                setSegmentsData(convertedResponse.segments);
            }
            fetchFareRulesForPNR()
        }
    }, [bookingData]);

    const fetchBookingDetails = async () => {
        let payload = {
            "recordLocator": recordLocator,
            "providerId": "S1_ADT",
            "agency": bookingAgency,
            "agent": bookingAgent
        }
        try {
            let res = await callGetBookingDetails(payload,correlationId);
            // handle the response
        } catch (error) {
            // handle the error
        }
    };

    const fetchFareRulesForPNR= async ()=>{
        let payload = {
            "PNR": recordLocator,
            "agency": bookingAgency,
            "agent": bookingAgent
        }
        try {
            let res = await faresRulesForPNR(payload,correlationId);
            // handle the response
        } catch (error) {
            // handle the error
        }

    }

    useEffect(() => {
        
        if (window._satellite) {
            var digitalData = {
                path: "Flights",
                page: {
                pageID: 204,
                pageName: "Confirmation",
                pageURL: window.location.href,
                referringURL: document.referrer,
                pageType: "Flight confirmation",
                language: "en-US",
                platform: "Desktop",
                },
            };
            window._satellite.track("dom-ready", { digitalData: digitalData });
        }
    }, []);

    return (
        <>
            <Confirmation
                getBookingDetailsData={getBookingDetailsData}
                PaymentMethod={PaymentMethod}
                getBookingDetailsInProgress={getBookingDetailsInProgress}
                faresRulesForPNR={faresRulesForPNR}
                fareRulesForPNRData={fareRulesForPNRData}
                quoteBookingData={quoteBookingData}
                giftCardPayload={giftCardPayload}
                flowFrom={flowFrom}
                bookingData={bookingData}
                segmentsData={segmentsData}
                language={language}
            />
        </>
    );
};

export default BookingConfirmationComponent;